<template>
    <div>
            <notEligible />
    </div>
</template>
<script>
import notEligible from '../../sub-components/notEligible';

export default{
components: {
    notEligible
  },
}
</script>