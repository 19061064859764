<template>
  <div class="container-fluid">
      <Header />
  <div class="row  contai">
    
    <div class=" w-100">
      <div class="d-flex flex-row justify-content-center align-items-center p-4">
        <img class='tick' alt="img" src="../../assets/img/white-check-mark.png">
      </div>
      
      <div class="h1">
        Sorry!
      </div>
      <div class="d-flex flex-row justify-content-center">
        <div class="box shadow d-flex flex-row text" style="height:15rem; width:80% !important; text-align:justify">
            
            <div style="padding:20px 10px 10px 10px;">
              You Are Currently Not Eligible for Loan Offers, due to Salary Parameters as per Bank's and NBFCs internal credit policies. We apologise for the same. 
              We would be happy to serve you in future!
               <br>
            <br>
            Stay Safe! Stay Financially Fit!<br>
            Thanks,<br>
            LoansJagat
            </div>

        </div>
      </div>
    
    </div>
  </div>
  </div>
</template>

<script>

import Header from '../sub-components/Header';

export default {
  name: "AcknowledgementThankyouPage1",
 
  components: {
    Header,
  },
 
};
</script>
<style scoped>
.h1{
    margin-bottom: 10px;
    color:#fff;
    font-size: 4rem;
    font-weight: 600;
    padding-bottom:1.5rem;
}
.p{
  color:#fff;
  font-size: 1.7rem;
  /* font-weight: 500; */
}
.contai{
  min-height:calc(100vh - 59px);
  background-color:#0255AB;
  text-align: center;
  /* position: fixed; */
}
.tick{
  width:10rem;
}
.box{
  margin-top:3.5rem;
  background-color:#fff;
  width:65%;
  height:13rem;
  color:#0255AB;
  border-radius: 5px;
}
.text{
  font-size: 2rem;
}
@media screen and (max-width: 767px) {
  .text{
    font-size: 2rem;
  }
  .tick{
    width:7rem;
  }
  .h1{
      margin-bottom: 10px;
      color:#fff;
      font-size: 3rem;
      font-weight: 600;
      padding-bottom:1.5rem;
  }
  .p{
    color:#fff;
    font-size: 1.3rem;
    /* font-weight: 500; */
  }

}
@media screen and (max-width: 480px) {
  .text{
    font-size: 1.2rem;
  }
  .tick{
    width:5rem;
  }

}
</style>
